*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "EB Garamond", serif;
    font-weight: 400;
    letter-spacing: 0.00001rem;
}
html, body {
    top: 0;
    margin: 0;
    /* height: 150%;  */
    /* overflow: hidden;  */
    background: #000;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    scrollbar-width: none;
}


.home__main{
    min-height: 200vh;
}

.main__container{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -46%) scale(var(--scale));
    height: 375px;
    width: 666px;
    background: url(../assets/purpleFlowerSprite.png) left top;
    filter: brightness(1.5);
    animation: play-sprite 0.1ms steps(29) 1;
    position: fixed;
}

.sprite__container{
    /* transform: translate(2%, +50%) scale(0.35); */
    top: 50%;
    left: 50%;
    transform: translate(-48%, -55%) scale(0.35);
    width: 2000px;
    height: 1000px;
    background: url(../assets/textSpriteEF.png) left top;
    position: fixed;
}

.arrow__container{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%) scale(0.5);
    width: 700px;
    height: 700px;
    background: url(../assets/arrowAnimationLarger.png) left top;
    filter: brightness(10);
    position: fixed;
}

.arrow__container.hidden{
    opacity: 0; 
}

.scroll__prompt{
    top: 50%;
    left: 50%;
    transform: translate(-50%, 100%);
    font-size: 2rem;
    position: fixed;
    color: white;
    opacity: 1; 
    animation: pulse 2.5s infinite; 
}

.scroll__prompt.hidden{
    opacity: 0; 
    animation: none;
}

@keyframes pulse {
    0% {
        opacity: 1; 
    }
    50% {
        opacity: 0; 
    }
    100% {
        opacity: 1; 
    }
}

.first__halftone{
    transform: translate(-25%, -25%) scale(0.5);
    width: 200%;
    height: 200vh;
    background: url(../assets/Texturelabs_Grunge_290XL.jpg) left top;
    position: fixed;
    mix-blend-mode:soft-light;
    opacity: 50%;
    pointer-events: none;
    z-index: 500;
}

.second__halftone{
    transform: translate(-25%, -25%) scale(0.5);
    width: 200%;
    height: 200vh;
    background: url(../assets/Texturelabs_Grunge_305XL.jpg) left top;
    position: fixed;
    mix-blend-mode:hard-light;
    opacity: 30%;
    pointer-events: none;
    z-index: 500;
}

.fan__out__container{
    position: fixed;
    z-index: 9111;
}

.fan__out__container p{
    color: white;
}

.fan-out-element{
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    position: absolute;
    cursor:grab;
    filter: brightness(0.9);
    opacity: 0;
}
  
.fan-out-element.visible {
    opacity: 1; 
}

.loading__gif{
    position: absolute;
    transform: scale(0.2);
}

@media screen and (max-width: 768px){
    .sprite__container{
        transform: translate(-48.75%, -55%) scale(0.2);
    }
}

@media screen and (max-width: 500px){
    #right-arrow, #left-arrow{
        opacity: 0;
    }
}

.contactpage__full{
    background: url(../assets/Texturelabs_Grunge_290XL.jpg) left top;
    width: 100vw;
    height: 100vh;
    background-color: #4d4d4d;
    background-blend-mode: difference;
    opacity: 0.65;
    position: absolute;
}

.contactpage__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-top: 15vh;
    font-size: 1.1rem;
    color: #ececec;
    max-width: 800px;
    width: 95vw;
    position: relative;
}

.contactform__container{
    position: fixed;
}

.contactpage__container label{
    align-self: flex-start;
    font-size: 1.2rem;
}

.contactpage__container p{
    font-size: 1.4rem;
}

.contactpage__container input{
    border-radius: 20px;
    border: none;
    background-color: #d6d6d6;
    color: black;
    padding: 10px;
    width: 100%;
    font-size: 1.1rem;
    outline-color: white;
}
.contactpage__container textarea{
    height: 20vh;
    width: 100%;
    border-radius: 20px;
    border: none;
    /* background-color: #4c4c4c; */
    background-color: #d6d6d6;
    color: black;
    resize: none;
    padding: 10px;
    font-size: 1.1rem;
    outline-color: white;
}

.contact__header{
    color: #ececec;
    align-self: flex-start;
}

#form__submit{
    margin-top: 10px;
    width: 100px;
    align-self: flex-start;
    cursor: pointer;
}

@media screen and (max-width: 768px){
    .contactpage__container{
        padding-top: 180px;
    }
}
.blog__page{
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../assets/blogPaper1.jpg') left top;
    width: 100vw;
    height: 100vh;
    background-color: white;
    /* mix-blend-mode: multiply; */
    /* opacity: 0.8; */
    position: absolute;
    overflow: hidden;
}

.mainblog_page{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: url('../assets/sky.jpg') no-repeat center center;
    overflow: hidden;
}

.blogpost__header{
    display: flex;
    flex-direction: column;
}

.blogpost__header h1{
    font-size: 3rem;
    cursor: pointer;
}

.blogpost__subheader{
    gap: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    /* align-self: center; */
    font-size: 1rem;
}

.blogpost_entry{
    cursor: pointer;
}

.blog__newspaper{
    background: url('../assets/blogPaper.png') no-repeat;
    transform: scale(1);
    /* margin-top:100px; */
    transform: translate(0%, 25%) scale(1);
    /* transform: scale(1); */
    background-size: contain;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 95vw;
    width: 900px;
    aspect-ratio: 16 / 20;
}

.blog__newspaper__wrapper {
    margin-top:15%;
    width: 100%;
    height: auto;
    max-height: 70vh;
    overflow-y: auto; 
    position: relative;
}

.blogentries__container{
    padding-bottom: 5vh;
    padding-left:4.25%;
    padding-right:4.25%;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
}

.paper__texture{
    width: 100%;
    height: 100%;
    background: url('../assets/blogPaper1.jpg') no-repeat;
    opacity: 0.6;
    filter: brightness(1.2);
    position: absolute;
    mix-blend-mode: multiply;
    pointer-events: none;
}

.paper__texture2{
    width: 100%;
    height: 100%;
    background: url('../assets/blogPaper1.jpg') no-repeat;
    opacity: 0.3;
    position: absolute;
    mix-blend-mode: screen;
    pointer-events: none;
}

.paper__byline{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top:12.675%;
    padding-left:4.35%;
    padding-right:4.35%;
    position: fixed;
    cursor: pointer;
}

.bodysnippet__container, .blogbody__container{
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: justify;
}

.blogbody__container{
    font-size: 1.2rem;
}

.blogcomments__container{
    display: flex;
    flex-direction: column;
}

.comment__replyclause{
    opacity: 0.6;
    cursor: pointer;
    user-select: none;
}

.blogcomments__container h2{
    margin-top: 30px;
}

.comment__metainfo{
    display: flex;
    flex-direction: row;
    gap: 20px;
    opacity: 0.6;
}

.addcomment__container{
    margin-top: 10px;
}

.comment__form{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.comment__form textarea{
    resize: vertical;
    background: none;
    max-height: 25vh;
    /* border-radius: 10px; */
    border-width: 1px;
    border-color: #909090;
    background: none;
    border-style: solid;
}

.comment__form button{
    /* border-radius: 10px; */
    border-width: 1px;
    border-color: #909090;
    background: none;
    width: 100px;
    border-style: solid;
}

.logincomment__container{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.comment__signin{
    text-decoration: underline;
    cursor: pointer;
}

.replying__container{
    margin-bottom: 10px;
}

.commentreplies__container{
    margin-left: 20px;
}

.reply__container{
    margin-bottom: 10px;
}

.blogcomment__container{
    margin-bottom: 10px;
}

@media screen and (max-width: 958px){
    .paper__byline p{
        font-size: 1.8vw;
    }

    .paper__byline{
        padding-top:12.6%;
    }

    .blog__newspaper{
        transform: translate(0%, 20%) scale(1);
    }

    .blog__newspaper__wrapper{
        max-height: 75vh;
    }

}

@media screen and (max-width: 428px){
    .blog__newspaper{
        transform: translate(0%, 0%) scale(1);
    }

    .blog__newspaper__wrapper {
        height: calc(((95vw / 16) * 20) * 0.88);
    }

    .blogpost__header h1{
        font-size: 2rem;
        cursor: pointer;
    }

    .bodysnippet__container p{
       font-size: 0.85rem;
    }

    .blogbody__container{
        font-size: 1em;
    }

    .blogcomment__container{
        font-size: 1em;
    }
}

#archive{
    background: url(../assets/archiveBackgroundGrey.png);
    mix-blend-mode: difference;
    background-position: -9vw 2vh;
    background-size: 120% auto;
    background-attachment: fixed;
}

.highlighted__work {
    position: relative;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.highlighted__work::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eeeeee;
    mix-blend-mode: difference;
    z-index: 1;
}

.highlighted__work img {
    position: relative;
    z-index: 2;
    mix-blend-mode: normal;
    cursor: pointer;
}

#main--highlight{
    /* width: 100%; */
    height: auto;
    max-width: 90vw;
    max-height: 70vh;
    object-fit: contain;
}

.grid__container{
    width: 90%;
    padding: 50px 0;
    margin: 0 auto;
    max-width: 1680px;
}

.column__container{
    display: flex;
    justify-content: center;
    gap: 20px;
    max-width: 1680px;
}

.img__container{
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    cursor: pointer;
    white-space: nowrap;
    gap: 20px;
    width: calc(1600px/var(--col-count));
}

.img__container img{
    width: 100%;
}

.overlay-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.9;
    pointer-events: none;
    mix-blend-mode: screen;
  }
  

.popup__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    max-width: 95vw;
    max-height: 95vh;
  }
  
  /* Main image styling */
.popup__image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%; /* Make sure the image does not exceed the container height */
}
  
.popup__image img {
    max-width: 95vw;  /* Adjust width as per requirement */
    max-height: 95vh; /* Maintain image height */
    transition: 0.05s ease;
    cursor: zoom-in;
}
  
.popup__image img.zoomed {
    transform: translate(var(--zoom-x), var(--zoom-y)) scale(var(--scale));
    cursor: zoom-out;
}
  
.popup__thumbnails {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    max-height: 95vh;
    max-width: 95vw;  
    overflow-y: auto;
    align-self: flex-start;
}
  
.popup__thumbnails img {
    width: 200px;
    height: auto;
    margin-bottom: 10px;
    object-fit: cover;
    transition: border 0.2s ease;
}
  
.popup__thumbnails img:hover {
    border: 2px solid #999;
}
  
  /* Overlay for dimming the background */
.popup__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
}
  
.popup__overlay button {
    position: absolute;
    background: none;
    padding: none;
    top: 10px;
    right: 20px;
    font-weight: bolder;
    color: #fff;
    cursor: pointer;
    font-size: 2rem;
    border: none;
}

.archivecollection__container{
    background-color: #eeeeee;
    background-blend-mode: difference;
}

.header__container{
    display: flex;
    flex-direction: row;
    color: black;
    align-items: flex-end;
    gap: 5px;
    margin-top: 10px;
    margin-left: 5vw;
    padding: 10px 0;
    cursor: pointer;
}

.header__container h2{
    margin: 0;
    font-size: 1.8rem;
}

.header__container p{
    margin: 0;
    font-size: 1.2rem;
}

.bird__background{
    height: 250px;
}

.bird__container{
    display: flex;
    align-items: center; 
    height: 0px;
    width: fit-content;
}

.bird__animation{
    top: 50%;
    left: 50%;
    transform: scale(0.5);
    width: 500px;
    height: 500px;
    background: url(../assets/birdAnimationSprite.png) left top;
}

.loading__text{
    color: white;
    position: absolute;
    transform: translateY(10vh);
    width: 100vw;
    text-align: center;
}

@media screen and (max-width: 768px){
    .grid__container {
        width: 100%;
        padding: 10px;
        margin: 0;
    }

    .img__container{
        gap: 8px;
        padding: 0; 
    }

    .column__container{
        gap: 8px;
        padding: 0;
    }

    .popup__container {
        flex-direction: column; /* Stack items on mobile */
        align-items: center; /* Center align items */
    }

    .popup__container img{
        max-height: 76.5vh;
    }
    
    .popup__thumbnails {
        margin-left: 0; /* Remove left margin on mobile */
        margin-top: 10px; /* Add some spacing above thumbnails */
        align-self: center; /* Center the thumbnails */
        flex-direction: row;
        overflow-x: auto;
        margin-bottom: 0;
    }

    .popup__thumbnails img{
        margin-bottom: 0;
    }

    .popup__thumbnails img {
        width: 100px;
    }

    @media screen and (max-width: 448px){
        .popup__container img{
            max-height: 95vh;
        }
    }
}

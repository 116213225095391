.navigation-container{
    display: flex;
    flex-direction: column;
    z-index: 99999;
    position: fixed;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 1.4rem;
    mix-blend-mode: difference;
    overflow: hidden; 
    height: auto;
    opacity: 1;
}

.inner-navigation-container{
    transition: height 0.5s ease, opacity 0.5s ease; 
}

.inner-navigation-container.collapsed {
    height: 0; 
    opacity: 0; 
}

.inner-navigation-container.expanded {
    height: auto;
    opacity: 1;
}

.navigation-container p{
    color: white;
}

.show__hide{
    cursor: pointer;
}

.link-container{
    display: flex;
    flex-direction: column;
    transition: opacity 0.5s ease;
}

.nav-link{
    color: white;
    text-decoration:none;
}